import { AppType, Contact } from "../../../features/entities/core-entities";
import {
  FormSubmissionResponse,
  TranslatedFormResponse,
} from "../../../features/entities/form-entities";
import useLocale from "../../../features/providers/locale-provider";
import ModalLayoutWithHdLogo from "../../common/hd-modal/layouts/modal-with-logo";
import FormViewer from "../../forms/form-viewer";
import HubspotForm from "../../hubspot/hubspot-form";
import { GrantInquiryModalStyled } from "./styles";

interface GrantInquiryModalProps {
  open: boolean;
  handleCancel: () => void;
  pageName: string;
  source: AppType;
  handleSaveSubmission: (props: FormSubmissionResponse) => Promise<string>;
  relatedFormResponse?: TranslatedFormResponse;
  contact?: Contact;
}

const GrantInquiryModal = ({
  open,
  handleCancel,
  relatedFormResponse,
  pageName,
  source,
  handleSaveSubmission,
  contact,
}: GrantInquiryModalProps) => {
  const { selectedLocale } = useLocale();
  if (!open) return <></>;

  return !!relatedFormResponse.form_id ? (
    <GrantInquiryModalStyled
      open={open}
      handleCancel={handleCancel}
      size="large"
      styles={{
        content: {
          padding: 0,
        },
      }}
      noPadding
    >
      <FormViewer
        type="modal"
        formResponse={relatedFormResponse}
        handleSave={handleSaveSubmission}
        handleClose={handleCancel}
        source={source}
        page_name={pageName}
        contact={contact}
      />
    </GrantInquiryModalStyled>
  ) : (
    <ModalLayoutWithHdLogo
      open={open}
      handleCancel={handleCancel}
      size="large"
      styles={{ content: { minHeight: 400 } }}
    >
      <>
        <HubspotForm
          containerTag="grant-inquiry-modal"
          hubspotId={
            selectedLocale === "en"
              ? "b50e2456-1d66-4e5c-9099-071683514c27"
              : "0b706767-afec-4eed-a2a6-daca16f9ecf5"
          }
          assignedTheme="Full Width"
        />
      </>
    </ModalLayoutWithHdLogo>
  );
};

export default GrantInquiryModal;

