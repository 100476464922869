import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Modal, {
  ModalProps,
} from "@hellodarwin/core/lib/components/common/hd-modal";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import FormItem from "@hellodarwin/core/lib/components/forms/form-layouts/form-item";
import FormLabel from "@hellodarwin/core/lib/components/forms/form-layouts/form-label";
import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/lib/components/forms/utils/company-infos";
import { Company, Contact } from "@hellodarwin/core/lib/features/entities";
import handleDispatchResponse from "@hellodarwin/core/lib/features/helpers/handle-dispatch-response";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Form, { useForm, useWatch } from "antd/es/form/Form";
import Input from "antd/es/input/Input";
import notification from "antd/es/notification";
import Select from "antd/es/select";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/index";
import { selectProvinces } from "../../features/api/slices/grants-slice";
import {
  selectContact,
  updateCompany,
  updateContact,
} from "../../features/api/slices/profile-slice";
import {
  selectIndustriesSectors,
  selectIndustriesSubsectorsForFundingExplorer,
} from "../../features/api/slices/tags-slice";
import { useClientApi } from "../../features/api/use-client-api";
interface FundingExplorerEditCompanyFieldsModalProps
  extends Omit<ModalProps, "children"> {
  company: Company;
}

interface FundingExplorerEditCompanyFormValues {
  companyName: string;
  contactName: string;
  companySize: string;
  companyAnnualRevenue: string;
  companyProvince: string;
  companyIndustries: string[];
  companyIndustriesSubsector: string[];
}
const FundingExplorerEditCompanyFieldsModal = ({
  company,
  ...modalProps
}: FundingExplorerEditCompanyFieldsModalProps) => {
  const { t } = useTranslation();
  const [form] = useForm<FundingExplorerEditCompanyFormValues>();
  const api = useClientApi();
  const dispatch = useAppDispatch();

  const contact = useAppSelector(selectContact);

  const formValues = useWatch([], form);
  const initialValues: FundingExplorerEditCompanyFormValues = useMemo(() => {
    return {
      companyName: company.name,
      contactName: contact.name,
      companySize: company.size ?? "",
      companyAnnualRevenue: company.annual_revenue ?? "",
      companyIndustries: company.industry_sector ?? [],
      companyIndustriesSubsector: company.industry_subsector ?? [],
      companyProvince: company.province ?? "",
    };
  }, [company, contact]);

  const provinces = useAppSelector(selectProvinces);

  const industrySectors = useAppSelector(selectIndustriesSectors);
  const filteredSubsectors = useAppSelector((state) =>
    selectIndustriesSubsectorsForFundingExplorer(
      state,
      formValues?.companyIndustries || []
    )
  );

  const handleSave = async () => {
    try {
      form.validateFields().then((values) => {
        const updatedCompany: Company = {
          ...company,
          name: values.companyName,
          province: values.companyProvince,
          industry_sector: values.companyIndustries,
          industry_subsector: values.companyIndustriesSubsector,
          size: values.companySize,
          annual_revenue: values.companyAnnualRevenue,
        };

        const data = new FormData();
        data.append("request", JSON.stringify(updatedCompany));

        const updatedContact: Contact = {
          ...contact,
          name: values.contactName,
        };

        const contactData = new FormData();
        contactData.append("request", JSON.stringify(updatedContact));

        dispatch(updateCompany({ api, data }))
          .then((res) =>
            dispatch(updateContact({ api, data: contactData })).then((res) =>
              handleDispatchResponse(
                res,
                t("notification.saved"),
                t("notification.saving_error")
              )
            )
          )
          .finally(modalProps.handleCancel);
      });
    } catch (error) {
      notification.error({
        message: t("notification.saving_error"),
        placement: "top",
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <Modal
      {...modalProps}
      size="large"
      styles={{
        content: { padding: "32px", width: "100%" },
        body: {
          maxWidth: 750,
          margin: "auto",
        },
      }}
    >
      <Typography elementTheme="h6" style={{ marginBottom: 16 }}>
        {t("profile|profileForm.company.titleModal")}
      </Typography>

      <Form
        layout="vertical"
        form={form}
        initialValues={company}
        onFinish={handleSave}
      >
        <Div flex="column" gap={16}>
          <Div flex="row" gap={32} tablet={{ flex: "column" }}>
            <FormItem
              style={{ flex: 1 }}
              label={
                <FormLabel
                  label={t("profile|profileForm.company.labels.name")}
                />
              }
              name={"companyName"}
              required={true}
            >
              <Input
                placeholder={t("profile|profileForm.company.placeholder.name")}
              />
            </FormItem>
            <FormItem
              style={{ flex: 1 }}
              label={
                <FormLabel
                  label={t("profile|profileForm.contact.labels.name")}
                />
              }
              name={"contactName"}
              required={true}
            >
              <Input
                placeholder={t("profile|profileForm.contact.placeholder.name")}
              />
            </FormItem>
          </Div>
          <Div flex="row" gap={32} tablet={{ flex: "column" }}>
            <FormItem
              style={{ flex: 1 }}
              label={
                <FormLabel
                  label={t("profile|profileForm.company.labels.size")}
                />
              }
              name={"companySize"}
              required={true}
            >
              <Select
                options={companySize}
                placeholder={t("profile|profileForm.company.placeholder.size")}
              />
            </FormItem>
            <FormItem
              style={{ flex: 1 }}
              label={
                <FormLabel
                  label={t("profile|profileForm.company.labels.annualRevenue")}
                />
              }
              name={"companyAnnualRevenue"}
              required={true}
            >
              <Select
                options={annualRevenue}
                placeholder={t(
                  "profile|profileForm.company.placeholder.annualRevenue"
                )}
              />
            </FormItem>{" "}
            <FormItem
              style={{ flex: 1 }}
              label={
                <FormLabel
                  label={t("profile|profileForm.address.labels.province")}
                />
              }
              name={"companyProvince"}
              required={true}
            >
              <Select
                options={provinces}
                placeholder={t(
                  "profile|profileForm.address.placeholder.province"
                )}
              />
            </FormItem>
          </Div>{" "}
          <FormItem
            style={{ flex: 1 }}
            label={
              <FormLabel
                label={t("profile|profileForm.company.labels.industrySector")}
              />
            }
            name={"companyIndustries"}
            required={true}
          >
            <Select
              mode="multiple"
              options={industrySectors}
              maxTagCount={3}
              placeholder={t(
                "profile|profileForm.company.placeholder.industrySector"
              )}
            />
          </FormItem>
          <FormItem
            style={{ flex: 1 }}
            label={
              <FormLabel
                label={t(
                  "profile|profileForm.company.labels.industrySubsector"
                )}
              />
            }
            name={"companyIndustriesSubsector"}
          >
            <Select
              options={filteredSubsectors}
              mode="multiple"
              maxTagTextLength={50}
              maxTagCount={3}
              placeholder={t(
                "profile|profileForm.company.placeholder.industrySubsector"
              )}
            />
          </FormItem>
        </Div>

        <Div
          flex="row"
          tablet={{ flex: "column" }}
          align="center"
          justify="flex-end"
          style={{ marginTop: 32 }}
        >
          <Button size="small" defaultStyle={theme.colors.primary}>
            Save
          </Button>
        </Div>
      </Form>
    </Modal>
  );
};

export default FundingExplorerEditCompanyFieldsModal;

