import AdminCard from "@hellodarwin/core/lib/components/admin/admin-card";
import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import ProjectHeader from "@hellodarwin/core/lib/components/project/single/project-header";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";
import ProjectType from "@hellodarwin/core/lib/features/enums/project-type";
import { getShortId } from "@hellodarwin/core/lib/features/helpers";
import useMediaQuery from "@hellodarwin/core/lib/features/helpers/use-media-query";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import { TabsProps } from "antd/es/tabs";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/index";
import ClientProjectSidebar from "../../../components/project/client-project-sidebar";
import ProjectActions from "../../../components/project/project-actions";
import ProjectDetails from "../../../components/project/project-details";
import ProjectEmptyState from "../../../components/project/project-empty-state";
import ProjectMatches from "../../../components/project/project-matches";
import ProjectMessages from "../../../components/project/project-messages";
import ProjectOverview from "../../../components/project/project-overview";
import {
  fetchAdmin,
  selectActiveTab,
  selectAdminByAdminId,
  setActiveTab,
} from "../../../features/api/slices/global-slice";
import {
  fetchProjectMatches,
  shortlist,
} from "../../../features/api/slices/matches-slice";
import { selectProfile } from "../../../features/api/slices/profile-slice";
import {
  fetchProject,
  selectProjectById,
  selectProjectsLoading,
  setSelectedProjectId,
} from "../../../features/api/slices/projects-slice";
import { useClientApi } from "../../../features/api/use-client-api";

type ClientSingleProjectPageProps = {
  defaultTab: "overview" | "brief" | "candidates" | "messages";
};

const ClientSingleProjectPage = ({
  defaultTab,
}: ClientSingleProjectPageProps) => {
  const api = useClientApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const isLarge = useMediaQuery({ maxWidth: theme.breakpoints.l });
  //Hooks

  const profile = useAppSelector(selectProfile);
  //---> Fetching Project
  const { projectId } = useParams<{
    projectId: string;
  }>();
  const project = useAppSelector((state) =>
    selectProjectById(state, projectId || "")
  );

  const adminId = useMemo(() => project?.account_manager || "", [project]);
  const admin = useAppSelector((state) => selectAdminByAdminId(state, adminId));
  useEffect(() => {
    dispatch(fetchAdmin({ api, adminId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminId]);

  const loading = useAppSelector((state) => selectProjectsLoading(state));

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProject({ api, projectId }));
      dispatch(fetchProjectMatches({ api, projectId }));
      dispatch(setSelectedProjectId(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  //---> Set Path
  const activeTab = useAppSelector(selectActiveTab);
  useEffect(() => {
    if (activeTab !== defaultTab) {
      dispatch(setActiveTab(defaultTab));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      !!project &&
      project.type === ProjectType.Classic &&
      [activeTab, defaultTab].includes("messages")
    ) {
      dispatch(setActiveTab("overview"));
      navigate(`/projects/${project?.project_id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, defaultTab, project]);

  useEffect(() => {
    if (project?.project_id) {
      if (["overview", "progress"].includes(activeTab)) {
        navigate(`/projects/${project?.project_id}`);
      } else {
        navigate(`/projects/${project?.project_id}/${activeTab}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const projectTile = useMemo(() => {
    if (!project) return "";
    return project.title && project.title?.length > 0
      ? project.title
      : project.service
        ? project.service
        : `${t("project|project")} ${getShortId(project.project_id)}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  //---> Prepare Tabs
  const tabs = useMemo(() => {
    const menuTabs: TabsProps["items"] = [];
    if (project) {
      if (isLarge) {
        menuTabs.push({
          label: t("project|projectTab.progress"),
          key: "progress",
          children: <ClientProjectSidebar project={project} />,
        });
      }

      menuTabs.push({
        label: t("project|projectTab.overview"),
        key: "overview",
        children: <ProjectOverview project={project} />,
      });

      menuTabs.push({
        label: t("project|projectTab.project"),
        key: "brief",
        children: <ProjectDetails project={project} />,
      });

      menuTabs.push({
        label: (
          <Div flex="row" gap={5}>
            {t("project|projectTab.candidates")}

            {project?.status !== ProjectStatus.Completed &&
              project?.status !== ProjectStatus.PendingApproval && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                >
                  <circle
                    cx="3.5"
                    cy="3.5"
                    r="3"
                    transform="matrix(-1 0 0 1 7 0)"
                    fill="#FF7070"
                    stroke="#FF4E4E"
                  />
                </svg>
              )}
          </Div>
        ),
        children: <ProjectMatches project={project} />,
        key: "candidates",
      });

      if (project.type === ProjectType.RaiseHand) {
        menuTabs.push({
          label: (
            <Div flex="row" gap={5}>
              {t("project|projectTab.messages")}
              {project?.status !== ProjectStatus.Completed &&
                project?.status !== ProjectStatus.PendingApproval && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                    fill="none"
                  >
                    <circle
                      cx="3.5"
                      cy="3.5"
                      r="3"
                      transform="matrix(-1 0 0 1 7 0)"
                      fill="#FF7070"
                      stroke="#FF4E4E"
                    />
                  </svg>
                )}
            </Div>
          ),
          key: "messages",
          children: <ProjectMessages project={project} />,
        });
      }
    }

    return menuTabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLarge, shortlist, project]);

  if (loading) return <></>;
  if (!project) return <ProjectEmptyState />;
  //Functions

  const handleTabClick = (tabKey: string) => {
    dispatch(setActiveTab(tabKey));
  };

  const handleBack = () => {
    navigate("/projects");
  };

  return (
    <>
      <PageLayout
        app="client"
        className={"client-single-project-page"}
        title={projectTile}
        handleBack={handleBack}
        breadcrumbs={[
          {
            breadcrumbName: t("navigation.home"),
            path: "/",
          },
          { breadcrumbName: t("project|yourProjects"), path: "/projects" },
          {
            breadcrumbName: projectTile,
          },
        ]}
      >
        <Div flex="column" gap={24}>
          <Div flex="row" gap={24}>
            <Container>
              <ProjectHeader
                project={project}
                tabs={tabs}
                activeTab={activeTab}
                handleTabClick={handleTabClick}
                locale={selectedLocale}
              />
            </Container>

            {!isLarge && (
              <Div
                style={{
                  width: 286,
                  flexBasis: 286,
                  flexGrow: 0,
                  flexShrink: 0,
                }}
                xxl={{ style: { flexBasis: 365, width: 365 } }}
                flex="column"
                gap={10}
              >
                <ClientProjectSidebar project={project} />
                <ProjectActions project={project} />
              </Div>
            )}
          </Div>
          {!!admin && (
            <AdminCard
              admin={admin}
              profile={profile}
              title={t("programs|singleDigitalTransfo.adminCard.title")}
              subtitle={t("programs|singleDigitalTransfo.adminCard.subtitle")}
            />
          )}
        </Div>
      </PageLayout>
    </>
  );
};

export default ClientSingleProjectPage;

