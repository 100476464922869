import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import { useTranslation } from "../../../../../plugins/i18n";
import Collapse from "../../../../common/Collapse";
import Typography from "../../../../common/typography";
import FilterCompanyCardContent, {
  FilterCompanyCardContentProps,
} from "./filter-company-card-content";

const FilterCompanyCard = (props: FilterCompanyCardContentProps) => {
  const { t } = useTranslation();

  return (
    <Collapse
      ExpandIcon={(props) => (
        <TabArrow
          down={props.isActive}
          width={12}
          height={8}
          style={{ marginRight: 16 }}
        />
      )}
      noPadding
      items={[
        {
          title: (
            <Typography style={{ padding: "4px 16px" }} elementTheme="body2">
              {t("grants|grantFilterCard.intro")}
            </Typography>
          ),
          children: <FilterCompanyCardContent {...props} />,
          id: "filtercompany-card",
        },
      ]}
    />
  );
};

export default FilterCompanyCard;

