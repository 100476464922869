import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import { FundingExplorerFilterValues } from "@hellodarwin/core/lib/components/grants/funding-explorer/filter/types";
import { Company } from "@hellodarwin/core/lib/features/entities/core-entities";
import useDebounce from "@hellodarwin/core/lib/features/hooks/use-debounce";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { ThemeProvider, useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { FormInstance, useWatch } from "antd/es/form/Form";
import notification from "antd/es/notification";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/index";
import {
  selectCompany,
  updateCompany,
} from "../../features/api/slices/profile-slice";
import { useClientApi } from "../../features/api/use-client-api";
import showErrorNotification from "../../features/utils/show-error-notifications";

interface FundingExplorerSaveCompanyProps {
  filterform: FormInstance<FundingExplorerFilterValues>;
}

const FundingExplorerSaveCompany = ({
  filterform,
}: FundingExplorerSaveCompanyProps) => {
  const api = useClientApi();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const company = useAppSelector(selectCompany);

  const filterValues = useWatch([], filterform);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [disableUpdateNotification, setDisableUpdateNotification] =
    useState(false);

  const isFieldUpdatable = (
    companyField: string | string[] | undefined,
    filterField: string | string[] | undefined
  ) =>
    (!companyField || companyField.length === 0 || companyField === "") &&
    filterField !== undefined &&
    filterField.length > 0;

  const isCompanyUpdatable = useDebounce(
    useMemo(() => {
      if (firstLoad) {
        setFirstLoad(false);
        return false;
      }

      return (
        isFieldUpdatable(company.industry_sector, filterValues?.industry) ||
        isFieldUpdatable(
          company.industry_subsector,
          filterValues?.subindustry
        ) ||
        isFieldUpdatable(company.province, filterValues?.region) ||
        isFieldUpdatable(company.size, filterValues?.companySize) ||
        isFieldUpdatable(company.annual_revenue, filterValues?.annualRevenue)
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, company]),
    1000
  );

  const updateCompanyInfo = async () => {
    const filterValues = filterform.getFieldsValue();
    const updatedCompany: Company = { ...company };
    if (
      (!company.industry_sector || company.industry_sector.length === 0) &&
      filterValues.industry !== undefined &&
      filterValues.industry.length > 0
    ) {
      updatedCompany.industry_sector = filterValues.industry;
    }
    if (
      (!company.industry_subsector ||
        company.industry_subsector.length === 0) &&
      filterValues.subindustry !== undefined &&
      filterValues.subindustry.length > 0
    ) {
      updatedCompany.industry_subsector = filterValues.subindustry;
    }
    if (
      (!company.province || company.province === "") &&
      filterValues.region !== undefined &&
      filterValues.region.length > 0
    ) {
      const region = filterValues.region?.[0];
      updatedCompany.province = region;
    }

    if (
      (!company.size || company.size === "") &&
      filterValues.companySize !== undefined &&
      filterValues.companySize !== ""
    ) {
      updatedCompany.size = filterValues.companySize;
    }

    if (
      (!company.annual_revenue || company.annual_revenue === "") &&
      filterValues.annualRevenue !== undefined &&
      filterValues.annualRevenue !== ""
    ) {
      updatedCompany.annual_revenue = filterValues.annualRevenue;
    }

    const data = new FormData();
    data.append("request", JSON.stringify(updatedCompany));

    await dispatch(updateCompany({ api, data }));
  };

  const openCompanyUpdateNotification = () => {
    notification.open({
      key: "updateCompany",
      message: t("grants|updateCompany"),
      placement: "top",
      duration: 3,
      pauseOnHover: true,
      closable: false,
      showProgress: true,
      btn: (
        <ThemeProvider theme={theme}>
          <Div flex="row" gap={8}>
            <Button
              size="small"
              defaultStyle={theme.colors.white_1}
              style={{
                minWidth: 120,
              }}
              onClick={() => {
                setDisableUpdateNotification(true);
                notification.destroy("updateCompany");
              }}
            >
              {t("button.no")}
            </Button>
            <Button
              size="small"
              style={{
                minWidth: 120,
              }}
              defaultStyle={theme.colors.primary}
              onClick={async () => {
                try {
                  await updateCompanyInfo();
                  notification.success({
                    message: t("notification.saved"),
                    placement: "bottomLeft",
                  });
                  notification.destroy("updateCompany");
                } catch (error: any) {
                  showErrorNotification(error.response.data.error_code);
                  console.error(error);
                }
              }}
            >
              {t("button.ok")}
            </Button>
          </Div>
        </ThemeProvider>
      ),
    });
  };

  useEffect(() => {
    if (!!isCompanyUpdatable && !disableUpdateNotification) {
      openCompanyUpdateNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompanyUpdatable]);

  return <></>;
};
export default FundingExplorerSaveCompany;

