import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/lib/components/forms/utils/company-infos";
import FundingExplorer from "@hellodarwin/core/lib/components/grants/funding-explorer";
import FilterCompanyCard from "@hellodarwin/core/lib/components/grants/funding-explorer/filter/filter-company-card/index";
import { FundingExplorerFilterValues } from "@hellodarwin/core/lib/components/grants/funding-explorer/filter/types";
import useApplyFundingExplorerFilter from "@hellodarwin/core/lib/components/grants/funding-explorer/hooks/apply-filters";
import { GrantProject } from "@hellodarwin/core/lib/features/entities/grants-entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { usePagination } from "@hellodarwin/core/lib/features/providers/pagination-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Form from "antd/es/form";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import FundingExplorerEditCompanyFieldsModal from "../../components/funding-explorer/funding-explorer-edit-company-fields-modal";
import FundingExplorerSaveCompany from "../../components/funding-explorer/funding-explorer-save-company";
import { QueryFundingExplorerProps } from "../../features/api/client-api-entities";
import {
  fetchGrantProjects,
  selectBestGrantProjects,
  selectGrantProjectsLoading,
} from "../../features/api/slices/grants-projects-slice";
import {
  fetchFundingExplorerTotalAmount,
  fetchGrantFinancingType,
  fetchGrantServices,
  fetchProvinces,
  queryFundingExplorer,
  selectAllFundingExplorerGrants,
  selectFundingExplorerTotalAmount,
  selectGrantFinancingType,
  selectGrantService,
  selectProvinces,
} from "../../features/api/slices/grants-slice";
import {
  fetchCompany,
  fetchContact,
  selectCompany,
  selectProfile,
} from "../../features/api/slices/profile-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectorsForFundingExplorer,
} from "../../features/api/slices/tags-slice";
import { useClientApi } from "../../features/api/use-client-api";
import GrantsDirectoryTotal from "./grants-directory-total";

const ClientFundingExplorer = () => {
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();
  const [filterform] = Form.useForm<FundingExplorerFilterValues>();
  const company = useAppSelector(selectCompany);
  const profile = useAppSelector(selectProfile);

  const [isLoading, setIsLoading] = useState(false);
  const showCompanyFilterCardState = useState<boolean>(true);
  const [showFilterCompanyCard] = showCompanyFilterCardState;

  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const financingType = useAppSelector(selectGrantFinancingType);
  const industries = useAppSelector(selectIndustriesSectors);
  const subindustries = useAppSelector((state) =>
    selectIndustriesSubsectorsForFundingExplorer(
      state,
      selectedIndustries || []
    )
  );
  const profileSubindustries = useAppSelector((state) =>
    selectIndustriesSubsectorsForFundingExplorer(
      state,
      company.industry_sector || []
    )
  );
  const provinces = useAppSelector(selectProvinces);
  const services = useAppSelector(selectGrantService);

  const grants = useAppSelector(selectAllFundingExplorerGrants);
  const totalAmount = useAppSelector(selectFundingExplorerTotalAmount);
  const bestProjects = useAppSelector(selectBestGrantProjects);
  const isGrantsProjectsLoading = useAppSelector(selectGrantProjectsLoading);
  const [selectedProjects, setSelectedProjects] = useState<GrantProject[]>([]);
  const { activePage, pageSize, addParam, getParam, removeParam } =
    usePagination();

  const isInitialCompanyValues = getParam("initialValues") === "true";

  const [oldFilterValues, setOldFilterValues] =
    useState<FundingExplorerFilterValues>();

  const [editCompanyModalOpen, setEditCompanyModalOpen] = useState(false);
  const handleEditCompanyModal = () => {
    setEditCompanyModalOpen(!editCompanyModalOpen);
  };
  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  const compareFilterValues = (
    newValues: FundingExplorerFilterValues
  ): [string, string] => {
    for (const key in oldFilterValues) {
      if (oldFilterValues.hasOwnProperty(key)) {
        const filterKey = key as keyof FundingExplorerFilterValues;
        const oldValue = oldFilterValues[filterKey];
        const newValue = newValues[filterKey];

        if (oldValue !== newValue) {
          return [filterKey, `${newValue}`];
        }
      }
    }

    return ["", ""];
  };

  const grantIds = useMemo(
    () => grants?.map((grant) => grant.grant_id),
    [grants]
  );

  useEffect(() => {
    if (grantIds?.length === 0 || !selectedLocale) return;
    dispatch(fetchGrantProjects({ api, locale: selectedLocale, grantIds }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantIds, selectedLocale]);

  const initData = async () => {
    setIsLoading(true);
    await dispatch(fetchCompany({ api }));
    await dispatch(fetchContact({ api }));
    await dispatch(fetchGrantFinancingType({ api, locale: selectedLocale }));
    await dispatch(fetchIndustries({ api, locale: selectedLocale }));
    await dispatch(fetchProvinces({ api, locale: selectedLocale }));
    await dispatch(fetchGrantServices({ api, locale: selectedLocale }));
  };

  const [missingDefaultFields, setMissingDefaultFields] = useState(false);

  useEffect(() => {
    setMissingDefaultFields(
      !company.name ||
        !company.annual_revenue ||
        !company.industry_sector ||
        !company.size ||
        !company.province ||
        !profile.contact_name
    );
  }, [company, profile]);

  const selectedProjectsIds = useMemo(
    () => selectedProjects.map((project) => project.grant_project_id),
    [selectedProjects]
  );

  const fetch = async () => {
    try {
      setIsLoading(true);
      const filterValues = filterform.getFieldsValue();

      setOldFilterValues(filterValues);
      const filterChanges = compareFilterValues(filterValues);

      const queryProps: QueryFundingExplorerProps = {
        locale: selectedLocale,
        page: activePage,
        limit: pageSize,
        companyId: undefined,
        ...filterValues,
        selectedProjects: selectedProjectsIds,
        fieldChanged: filterChanges[0],
        valueChanged: filterChanges[1],
      };

      await dispatch(queryFundingExplorer({ api, ...queryProps }));
      await dispatch(fetchFundingExplorerTotalAmount({ api, ...queryProps }));

      setIsLoading(false);
      queryProps.textQuery
        ? addParam("search", queryProps.textQuery)
        : removeParam("search");
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const applyFilter = useApplyFundingExplorerFilter({
    fetch,
    form: filterform,
    selectedIndustries,
    setSelectedIndustries,
    setSelectedProjects,
    company,
    showCompanyFilterCardState,
  });

  const hasGrants = useMemo(
    () => grants !== undefined && grants?.length > 0,
    [grants]
  );

  return (
    <PageLayout
      app="client"
      title={t("navigation.fundingExplorer")}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.fundingExplorer"),
        },
      ]}
      actions={
        hasGrants ? (
          <GrantsDirectoryTotal totalAmount={totalAmount} grants={grants} />
        ) : (
          !isLoading && (
            <Typography elementTheme="h5" style={{ fontWeight: 400 }} nowrap>
              {t(`grants|noGrants`)}
            </Typography>
          )
        )
      }
      styles={{ container: { overflow: "visible" } }}
    >
      <FundingExplorer
        app="client"
        initialQuerySearch={getParam("search")}
        afterFilters={
          showFilterCompanyCard &&
          isInitialCompanyValues && (
            <FilterCompanyCard
              company={company}
              industries={industries}
              subIndustries={profileSubindustries}
              annualRevenue={annualRevenue}
              companySize={companySize}
              provinces={provinces}
            />
          )
        }
        filterform={filterform}
        missingDefaultFields={missingDefaultFields}
        company={company}
        financingType={financingType}
        industries={industries}
        subIndustries={subindustries}
        provinces={provinces}
        services={services}
        companySize={companySize}
        annualRevenue={annualRevenue}
        applyFilter={applyFilter}
        selectedIndustries={selectedIndustries}
        setSelectedIndustries={setSelectedIndustries}
        bestProjects={bestProjects}
        grantsProjectsLoading={isGrantsProjectsLoading}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        isLocked={!!missingDefaultFields}
        goToSettings={handleEditCompanyModal}
        isLoading={isLoading}
        grants={grants}
      />

      <FundingExplorerEditCompanyFieldsModal
        open={editCompanyModalOpen}
        handleCancel={handleEditCompanyModal}
        company={company}
      />
      <FundingExplorerSaveCompany filterform={filterform} />
    </PageLayout>
  );
};

export default ClientFundingExplorer;

